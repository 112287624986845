<template>
  <v-menu
    bottom
    right
    offset-y
    :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        color="#f7f7f7">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>
    <v-card min-width="130" max-width="330" tile>
      <v-list nav dense>
        <v-subheader>
          Portals
          <v-icon small class="ml-2">mdi-launch</v-icon>
        </v-subheader>
        <div :style="containerStyle">
          <v-list-item
            v-for="portal in availablePortals"
            :key="portal.key"
            class="portal-link"
            link
            @click="navigate(portal)"
            style="min-height: auto">
            <div class="portal-link-content">
              <v-btn
                plain
                icon
                class="mx-auto">
                <v-icon>{{ portal.icon }}</v-icon>
              </v-btn>
              <v-list-item-action-text class="pa-0 ma-0">
                {{ portal.name }}
              </v-list-item-action-text>
            </div>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { userAccess } from '@/mixins/user-access'
export default {
  data() {
    return {
      retailerKeys: ['item', 'partner']
    }
  },
  name: 'PortalNavMenu',
  props: {
    active: String
  },
  mixins: [userAccess],
  computed: {
    portals () {
      return [
        { name: 'Ad Planner', key: 'adplan', icon: 'mdi-newspaper-variant-outline' },
        { name: 'Billing', key: 'billing', icon: 'mdi-calendar-clock' },
        { name: 'Customer', key: 'customer', icon: 'mdi-storefront' },
        { name: 'Items', key: 'item', icon: 'mdi-barcode-scan' },
        { name: this.$auth.tenant === 'jbg' ? 'Documents' : 'Partner', key: 'partner', icon: 'mdi-folder-account-outline' },
        { name: 'Promotions', key: 'promo', icon: 'mdi-tag-outline' },
        { name: 'Product Catalog', key: 'products', icon: 'mdi-package', tenants: ['jbg'] }
      ]
    },
    availablePortals () {
      if (this.$auth.tenant === 'jbg' && this.isJBGRetailer) {
        return this.retailerPortals
      }
      return this.portals.filter(portal => portal.tenants ? portal.tenants.includes(this.$auth.tenant) : true)
    },
    retailerPortals () {
      const keys = this.isProductCatalogRetailer
        ? [...this.retailerKeys, 'products']
        : this.retailerKeys
      return this.portals.filter(portal => {
        return portal.key !== this.active && keys.includes(portal.key)
      })
    },
    cols () {
      const portalCount = this.availablePortals.length
      return (portalCount <= 3) ? portalCount : 3
    },
    containerStyle () {
      return (
        `display: grid;
        grid-template-columns: repeat(${this.cols}, 1fr);
        grid-gap: 3px;`
      )
    }
  },
  methods: {
    navigate (portal) {
      if (portal.key === this.active) return
      const url = this.buildPortalUrl(portal.key)
      window.location.href = url
    },
    buildPortalUrl (key) {
      const baseUrl = this.getPortalBaseUrl(key)
      const prefix = this.$auth.tenantURLPrefix
      if (prefix) {
        return `https://${prefix}.${baseUrl}`
      }
      return `https://${baseUrl}`
    },
    getPortalBaseUrl (key) {
      switch (key) {
        case 'adplan':
          return process.env.VUE_APP_ADPLAN_PORTAL
        case 'billing':
          return process.env.VUE_APP_BILLING_PORTAL
        case 'customer':
          return process.env.VUE_APP_CUSTOMER_PORTAL
        case 'item':
          return process.env.VUE_APP_ITEM_PORTAL
        case 'partner':
          return process.env.VUE_APP_PARTNER_PORTAL
        case 'promo':
          return process.env.VUE_APP_PROMO_PORTAL
        case 'products':
          return process.env.VUE_APP_PRODUCTS_PORTAL
        default:
          return ''
      }
    }
  }
}
</script>
<style>
.portal-link {
  display: grid;
  justify-content: center;
  padding: 4px;
  height: 100%;
}

.portal-link-content {
  display: grid;
  justify-content: center;
  max-height: 55px;
  text-align: center;
}
</style>
